import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../shared/context/authContext/AuthContext";
import { ACTIONS } from "../../shared/context/authContext/Constants";
import { AUTH_ROUTES, STORAGE_KEYS } from "../../shared/routes/Constants";
import { loginRequest } from "../../shared/services/auth/auth.service";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IUser } from "../../shared/interfaces/user.interface";
import { updateAnalytics } from "../../shared/services/analytics/analytics.service";

interface IForm {
    email: string;
    password: string;
}

const SigninSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required."),
    password: Yup.string().min(6).required("Password is required."),
});

const Login = () => {
    const history = useHistory();
    const { dispatch } = useContext(AuthContext);

    const handleLogin = async (
        values: IForm,
        setFieldError: (field: string, message: string | undefined) => any
    ) => {
        const { response, errors } = await loginRequest({
            ...values,
            email: values.email.toLowerCase(),
        });
        if (errors) {
            const message = errors.map((x) => x.message).join("");

            if (message === "Wrong Password.") {
                setFieldError("password", "Password is incorrect.");
            } else if (message === "User not found.") {
                setFieldError(
                    "email",
                    "Email not found. Please register before signing in."
                );
            }
        }

        if (response) {
            const user: IUser = response.user;
            dispatch({
                type: ACTIONS.LOGIN,
                token: {
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken,
                },
            });

            dispatch({
                type: ACTIONS.SET_USER,
                user: response.user,
            });

            await updateAnalytics({ lastLogin: Date() });

            const redirect = new URLSearchParams(history.location.search).get(
                "redirect"
            );

            if (redirect) {
                history.push(redirect);
            } else {
                if (user && !user.firstLoginWeb) {
                    localStorage.setItem("preview-video", "1");
                }
                const gameSettings = localStorage.getItem(STORAGE_KEYS.GAME_SETTINGS);
                if(gameSettings){
                    history.push({
                        pathname: `/app/lets-play`,
                        search: gameSettings.toString(),
                    });
                    localStorage.removeItem(STORAGE_KEYS.GAME_SETTINGS);
                }else{
                    history.push('/app/lets-play');
                }
            }
        }
    };

    return (
        <div className={"pt-14 md:pt-40 pb-12"}>
            <div className={"md:w-2/5 m-auto"}>
                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    validationSchema={SigninSchema}
                    onSubmit={async (values, { setFieldError }) =>
                        handleLogin(values, setFieldError)
                    }
                >
                    {({ errors, touched }) => (
                        <Form className={"space-y-6 "}>
                            <div>
                                <Field
                                    name="email"
                                    autoComplete={"new-password"}
                                    placeholder="EMAIL"
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="email"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>
                            <div>
                                <Field
                                    name="password"
                                    placeholder="PASSWORD"
                                    type="password"
                                    autoComplete={"new-password"}
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="password"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>
                            <div className="pt-1">
                                <Link
                                    to={`${AUTH_ROUTES.FORGOT_PASSWORD}`}
                                    className={"text-xs text-aha-green pt-1"}
                                >
                                    Forgot password?
                                </Link>
                            </div>
                            <div className={"text-center py-6"}>
                                <button
                                    className={
                                        "border border-aha-green bg-aha-green text-white py-2 px-14 font-bold  hover:text-aha-green hover:bg-white transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider"
                                    }
                                    type={"submit"}
                                >
                                    LOG IN
                                </button>
                                <div>
                                    <Link
                                        to={`${AUTH_ROUTES.REGISTER}`}
                                        className={
                                            "text-xs text-aha-green pt-1"
                                        }
                                    >
                                        New user? Register here.
                                    </Link>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Login;
