import React, { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { newPasswordRequest } from "../../shared/services/auth/auth.service";

const ForgotPassword = () => {
    const [formData, setFormData] = useState({
        email: "",
    });

    const onSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        const { response, errors } = await newPasswordRequest(
            formData.email.toLowerCase()
        );
        if (response) {
            toast.success("Please check your email to reset the password.");
            setFormData({
                email: "",
            });
        }
    };

    return (
        <div className={"pt-14 md:pt-40 pb-12"}>
            <div className={"md:w-2/5 m-auto"}>
                <form action="#" className={"space-y-6 "} onSubmit={onSubmit}>
                    <div>
                        <input
                            required
                            type="email"
                            placeholder="EMAIL"
                            className={
                                "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                            }
                            autoComplete={"new-password"}
                            value={formData.email}
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        email: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>

                    <div className={"text-center py-6"}>
                        <button
                            className={
                                "border border-aha-green bg-aha-green text-white py-2 px-14 font-bold  hover:text-aha-green hover:bg-white transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider"
                            }
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
