import { flatMap } from "lodash";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AuthContext from "../../shared/context/authContext/AuthContext";
import { IGame } from "../../shared/interfaces/game.interface";
import { ILevel } from "../../shared/interfaces/level.interface";
import {
    ISound,
    ISoundResponse,
} from "../../shared/interfaces/sound.interface";
import {
    IPlayAGamePayload,
    getGameOptionsRequest,
    playAGameRequest,
} from "../../shared/services/game/game.service";
import backButtonImage from "./../../../assets/images/back-button.png";
import lockIcon from "./../../../assets/images/lock.png";
import LevelSelectorModal from "./LevelSelectorModal";
import PositionAndLevelInfoModal from "./PositionAndLevelInfoModal";
import SoundSelectorModal from "./SoundSelectorModal";
import SubscriptionModal from "./SubscriptionModal";
import ModalAfterLogin from "../../shared/components/OneTimeModal";
import { updateUser } from "../../shared/services/user/user.service";
import VideoModal from "./VideoModal";
import TutorialButton from "./components/TutorialButton";
import { wordPositionEnum } from "../../shared/enums/word-position.enum";
import { LevelEnum } from "../../shared/enums/level.enum";
import ReactTooltip from "react-tooltip";
import { InformationCircleIcon } from "@heroicons/react/solid";

type WordPositionType = {
    title: string;
    subTitle: string;
}

const LetsPlay = () => {
    const history = useHistory();
    const location = useLocation();
    const params = {
        sound: new URLSearchParams(location.search).get("sound"),
        level: new URLSearchParams(location.search).get("level"),
        wordPosition: new URLSearchParams(location.search).get("wordPosition"),
    }

    const { state } = useContext(AuthContext);

    const [formData, setFormData] = useState<any>({
        game: null,
        sound: null,
        wordPositions: [],
        level: null,
        setMode: 0,
    });

    const [sounds, setSounds] = useState<ISound[]>([]);

    const [showSoundsModal, setShowSoundsModal] = useState(false);

    const [showOneTimeModal, setShowOneTimeModal] = useState(false);

    const [selectedSound, setSelectedSound] = useState<ISound>();

    const [childSelectedSound, setchildSelectedSound] = useState<ISound>();

    const [wordPositions, setWordPositions] = useState<WordPositionType[]>([]);

    const [wordPositionsAllowedBySound, setWordPositionsAllowedBySound] = useState<wordPositionEnum[]>([]);

    const [levelAllowedBySound, setLevelsAllowedBySound] = useState<LevelEnum[]>([]);

    const [wordPositionsSelected, setWordPositionsSelected] = useState<WordPositionType[]>([]);

    const [levels, setLevels] = useState<ILevel[]>([]);

    const [showLevelsModal, setShowLevelsModal] = useState(false);

    const [readingSelectedLevel, setReadingLevelSelected] = useState<any>();

    const [levelSelected, setLevelSelected] = useState<ILevel>();

    const [games, setGames] = useState<IGame[]>([]);

    const [inputLevel, setInputLevel] = useState(0);

    const [allSelected, setAllSelected] = useState(false);

    const [lockSubscription, setLockSubscription] = useState(false);

    const [activeInfoModal, setActiveInfoModal] = useState<
        "WORDPOSITION" | "LEVEL" | "SOUND" | "NONE"
    >("NONE");

    const [isVideoModalOpen, setIsVideoModalOpen] = useState(
        localStorage.getItem("preview-video") === "1"
    );

    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

    const fetchListGames = async () => {
        const { response } = await getGameOptionsRequest();

        const childrenSounds: ISound[] = flatMap(
            response.sounds.map((sound: ISoundResponse) => sound.children)
        );

        const sounds = response.sounds
            .filter((sound: ISoundResponse) => {
                return (
                    childrenSounds.findIndex(
                        (child: ISound) => child.id === sound.parent.id
                    ) === -1
                );
            })
            .map((sound: ISoundResponse) => {
                return {
                    ...sound.parent,
                    children: sound.children,
                };
            });

        setSounds(sounds);

        const wordPositionsWithAll = [ ...response.wordPositions.map((x: string) => {
            return {
                title: x,
                subTitle: "",
            };
        }), {title: "ALL", subTitle: ""}];

        setWordPositions(
            wordPositionsWithAll
        );

        const levels = response.levels.map((level: ILevel) => {
            if (level.title === "READING") {
                return {
                    ...level,
                    children: response.readingLevels,
                };
            }

            return level;
        })

        setLevels(levels);

        if(params?.sound){            
            sounds.forEach((x: ISound) => {
                let sound: ISound | undefined;

                if(x.children.length){
                    sound = x.children.find((y: ISound) => y.title === params.sound);
                    if(sound) {
                        setchildSelectedSound(sound);
                    }
                }
                
                if(x.title === params.sound){
                    sound = x;
                }

                if(sound){
                    setSelectedSound(sound);
                    setWordPositionsAllowedBySound(sound.wordPositionsAllowed || []);
                    setLevelsAllowedBySound(sound.levelsAllowed || []);

                    if(params.wordPosition && sound.wordPositionsAllowed?.includes(params.wordPosition as wordPositionEnum)){
                        setWordPositionsSelected(wordPositionsWithAll.filter((x: WordPositionType) => x.title === params.wordPosition));
                    }

                    const level = levels.find((x: ILevel) => x.title === params.level);
            
                    if(level && sound.levelsAllowed?.includes(level.title)){
                        setLevelSelected(level);              
                        setReadingLevelSelected(level);
                        if(level.title === 'READING'){
                            setShowLevelsModal(true);                        
                        }
                    }

                    setFormData((currentState: any) => {
                        return {
                            ...currentState,
                            sound: sound?.id,
                            level: level?.id,
                        };
                    });
                }
            });        
        }

        setGames(response.games.filter((x: any) => !x.reinforcement));
    };

    useEffect(() => {
        if (state) {
            if(state && state.user && !state.user?.hideLoginPopUpWeb){
                setShowOneTimeModal(true);
                updateUser({ hideLoginPopUpWeb: true });
            }
            setLockSubscription(state?.user?.subscriptionTier === "FREE");
        }
    }, [state]);

    useEffect(() => {
        fetchListGames();
    }, []);

    useEffect(() => {
        setFormData((currentState: any) => {
            return {
                ...currentState,
                wordPositions: wordPositionsSelected.filter(word => word.title !== "ALL"),
            };
        });
    }, [wordPositionsSelected]);

    useEffect(() => {
        const { sound, wordPositions, level, game } = formData || {};

        let step = 0;

        if (sound != null && !wordPositions.length) {
            step = 1;
        }

        if (wordPositions.length && level == null) {
            step = 2;
        }

        if (level != null && game == null) {
            step = 3;
        }

        if (game != null) {
            step = 4;
        }

        if (sound && wordPositions?.length && level && game) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }

        setInputLevel(step);
    }, [formData]);

    const onSubmit = async (readingLevel = undefined) => {
        let amount = 10;
        let gameTitle: string = formData.game?.title?.toLocaleLowerCase();
        if(gameTitle?.includes('puzzle')){
            amount = 25;
        }        
        const payload: IPlayAGamePayload = {
            game: formData.game?.id,
            level: formData.level,
            sound: formData.sound,
            wordPositions: formData.wordPositions.map(
                (position: any) => position.title
            ),
            amount,
            readingLevel: readingLevel,
            deviceType: 'WEB'
        };
        
        try {
            const { response, errors } = await playAGameRequest(payload);

            if (response) {
                localStorage.setItem("gamePayload", JSON.stringify(formData));
                localStorage.setItem("gameAssets", JSON.stringify(response));

                //Set the selected level
                localStorage.setItem(
                    "levelSelected",
                    JSON.stringify(levelSelected)
                );

                history.push("/app/game");
            }
            if(errors?.length){
                toast.error(errors[0]?.message || 'Something went wrong');
            }
        } catch (error: any) {
            toast.error(error?.message);
        }
    };

    const renderStep1 = () => {
        const renderSoundItem = (sound: ISound, index: number) => (
            <div
                key={sound.id}
                className={`justify-self-center  rounded-full h-14 w-14 text-white flex flex-col  justify-center hover:bg-aha-orange cursor-pointer transition ease-in-out duration-200 relative
                                        ${
                                            formData.sound === sound.id ||
                                            sound.children.find(
                                                (childrenSound) =>
                                                    childrenSound.id ===
                                                    formData?.sound
                                            )
                                                ? "bg-aha-orange"
                                                : "bg-aha-black"
                                        }

                                        ${
                                            lockSubscription &&
                                            sound.subscription
                                                ? "cursor-not-allowed"
                                                : null
                                        }
                                    `}
                onClick={() => {
                    setWordPositionsAllowedBySound(sound.wordPositionsAllowed || []);
                    setLevelsAllowedBySound(sound.levelsAllowed || []);
                    setWordPositionsSelected([]);
                    setLevelSelected(undefined);
                    if (!(lockSubscription && sound.subscription)) {
                        if (sound.children.length) {
                            setSelectedSound(sound);          
                            setWordPositionsSelected([]);
                            setShowSoundsModal(true);
                        }

                        setFormData((currentState: any) => {
                            return {
                                ...currentState,
                                level: undefined,
                                sound:
                                    currentState.sound === sound.id
                                        ? null
                                        : sound.id,
                            };
                        });
                    } else {                        
                        setShowSubscriptionModal(true);
                    }
                }}
            >
                {lockSubscription && sound.subscription ? (
                    <div className="absolute -top-1 -right-1 h-5 w-5 pointer-events-none z-50">
                        <img src={lockIcon} alt="Lock" />
                    </div>
                ) : null}
                <div className={"tracking-widest font-century-gothic-bold"}>
                    {sound.title}
                </div>
                {sound.subTitle && (
                    <div className={"text-xs -mt-2 tracking-widest"}>
                        {sound.subTitle}
                    </div>
                )}
            </div>
        );

        return (
            <div
                className={`flex-1 border-4 border-gray text-center pb-4 relative  ${
                    inputLevel === 0 && "bg-gray-100"
                }`}
            >
                <div className="mb-3 mt-6">
                    <div className="mx-auto border w-max border-black rounded-full px-3">
                        Step 1
                    </div>

                    <div className="my-5 text-aha-black">
                        <p className="text-2xl mb-2 font-century-gothic-bold">
                            Sounds
                        </p>
                        <p className="">Choose the sound your</p>
                        <p className="">child is working on</p>
                    </div>

                    <TutorialButton
                        type="sound"
                        onClick={() => setActiveInfoModal("SOUND")}
                    />
                </div>

                <div
                    className={`grid grid-rows-5 grid-flow-col gap-3 items-center py-6  ${
                        sounds.length < 10 ? "mx-12" : "mx-2"
                    } font-blanch-regular text-2xl`}
                >
                    {sounds.map((sound: ISound, index: number) =>
                        renderSoundItem(sound, index)
                    )}
                </div>
            </div>
        );
    };

    const renderStep2 = () => {
        

        const highLightItem = (item: WordPositionType) => {
            if( wordPositionsSelected.some( word => word.title === item.title) && item.title === 'ALL' ){
                return true;
            }

            if( !wordPositionsSelected.some( word => word.title === 'ALL') ){
                return wordPositionsSelected.some(word => word.title === item.title);
            }

            return false

        } 

        const isAllowedToSelect = (item: WordPositionType) => wordPositionsAllowedBySound.includes(item.title as wordPositionEnum);
        const hasAllowedValues = (item: WordPositionType) => wordPositions.some( w => wordPositionsAllowedBySound.includes(w.title as wordPositionEnum)) && item.title ===  'ALL';

        const renderWordItem = (position: any, index: number) => (            
            <div
                key={position.title + index}
                className={`py-3 ${isAllowedToSelect(position) || hasAllowedValues(position) ? 'hover:bg-aha-orange cursor-pointer transition ease-in-out duration-200' : '' }
                        ${
                            highLightItem(position)
                                ? "bg-aha-orange"
                                : isAllowedToSelect(position) || hasAllowedValues(position) ? "bg-aha-black" : "bg-gray-300"
                        }
                        `}
                onClick={() => {
                    if(isAllowedToSelect(position) || position.title === 'ALL'){
                        setWordPositionsSelected(currentState  => {
                            const response = [ ...wordPositionsAllowedBySound.map( value => { return {
                                title: value,
                                subTitle: ''
                            }}), { title: 'ALL', subTitle: '' }]
                            if( !currentState.some(word => word.title === position.title) && position.title === 'ALL' ){
                                return response;
                            }
    
                            if( currentState.some(word => word.title === position.title) && position.title === 'ALL' ){
                                return [];
                            }
    
                            if( currentState.some(word => word.title === position.title) ){
                                if(currentState.some(word => word.title === 'ALL') ){
                                    return currentState.filter( (x: any) => x.title === position.title ).filter( (x: any) => x.title !== 'ALL' );
                                }else{
                                    return currentState.filter( (x: any) => x !== position );
                                }
                            }
                            
                            if( !currentState.some(word => word.title === position.title) ){
                                const itemsSelected = [...currentState, position];
    
                                return itemsSelected.length === response.length -1 ? response : itemsSelected;
                            }
                        
                            return currentState;
                        });
                    }                        
                }}
            >
                <div
                    className={
                        "text-xl tracking-widest font-century-gothic-bold"
                    }
                >
                    {position.title}
                </div>
                <div className={"font-blanch-light tracking-wide text-3xl"}>
                    {position.subTitle}
                </div>
            </div>
        );

        return (
            <div
                className={`flex-1 border-4 border-gray text-center pb-4 relative ${
                    inputLevel === 1 && "bg-gray-100"
                }`}
            >
                <div className="mb-3 mt-6">
                    <div className="mx-auto border w-max border-black rounded-full px-3">
                        Step 2
                    </div>

                    <div className="my-5 text-aha-black">
                        <p className="text-2xl mb-2 font-century-gothic-bold">
                            Word Position
                        </p>
                        <p className="">Choose the position of</p>
                        <p className="">the sound in words</p>
                    </div>

                    <TutorialButton
                        type="word"
                        onClick={() => setActiveInfoModal("WORDPOSITION")}
                    />
                </div>

                <div
                    className={
                        "space-y-8 uppercase pt-8 px-2 md:px-8 text-white"
                    }
                >
                    {wordPositions.map((position: any, index: number) =>
                        renderWordItem(position, index)
                    )}
                </div>
            </div>
        );
    };

    const renderStep3 = () => {
        const isAllowedToSelect = (level: ILevel) => level.title === 'READING' ? 
        wordPositionsSelected.length < 2 && levelAllowedBySound.includes(level.title as LevelEnum) : 
        levelAllowedBySound.includes(level.title as LevelEnum);

        const renderLevelItem = (level: ILevel | any, index: number) => (
            <div
                key={level.id}
                className={` py-3 relative ${isAllowedToSelect(level) ? 'hover:bg-aha-orange cursor-pointer transition ease-in-out duration-200' : '' }
                                    ${
                                        formData.level === level.id
                                            ? "bg-aha-orange"
                                            : isAllowedToSelect(level) ? "bg-aha-black" : "bg-gray-300"
                                    }
                                    ${
                                        lockSubscription && level.subscription
                                            ? "cursor-not-allowed"
                                            : null
                                    }
                                    `}
                onClick={() => {
                    if(isAllowedToSelect(level)){
                        if (!(lockSubscription && level.subscription)) {
                            setLevelSelected(level);
                            if (level?.children?.length) {
                                setShowLevelsModal(true);
                                setReadingLevelSelected(level);
                            }
                            setFormData((currentState: any) => {
                                return {
                                    ...currentState,
                                    level:
                                        currentState.level === level.id
                                            ? null
                                            : level.id,
                                    setMode: index,
                                };
                            });
                        } else {
                            setShowSubscriptionModal(true);
                        }
                    }
                }}
            >
                {
                    level.title === 'READING' && !isAllowedToSelect(level) && wordPositionsSelected?.length ? (
                        <>
                            <InformationCircleIcon data-tip data-for="readingLevelTip" className={"hover:cursor-pointer w-4 text-white absolute top-0 right-0"} />
                            <ReactTooltip id="readingLevelTip" type="info" place="top" effect="solid">
                                <span>
                                    Note: Reading level prompts require one word position only
                                </span>
                            </ReactTooltip>
                        </>
                    ) : null
                }
                {lockSubscription && level.subscription ? (
                    <div className="absolute w-6 h-6 -top-3 -right-2  pointer-events-none z-50">
                        <img src={lockIcon} alt="Lock" />
                    </div>
                ) : null}
                <div
                    className={
                        "text-2xl tracking-wider font-century-gothic-bold relative"
                    }
                >
                    {level.title}
                </div>
            </div>
        );

        return (
            <div
                className={`flex-1 border-4 border-gray text-center pb-4 relative ${
                    inputLevel === 2 && "bg-gray-100"
                }`}
                style={{ minHeight: 600 }}
            >
                <div className="mb-3 mt-6">
                    <div className="mx-auto border w-max border-black rounded-full px-3">
                        Step 3
                    </div>

                    <div className="my-5 text-aha-black">
                        <p className="text-2xl mb-2 font-century-gothic-bold">
                            Level
                        </p>
                        <p className="">
                            Select the level of speech complexity
                        </p>
                        <p className="">your child is working on</p>
                    </div>

                    <TutorialButton
                        type="level"
                        onClick={() => setActiveInfoModal("LEVEL")}
                    />
                </div>

                <div
                    className={
                        "space-y-8 uppercase pt-8 px-2 md:px-8 text-white"
                    }
                >
                    {levels.map((level: ILevel | any, index: number) =>
                        renderLevelItem(level, index)
                    )}
                </div>
            </div>
        );
    };

    const renderStep4 = () => {
        const renderGameItem = (game: IGame, index: number) => (
            <div
                key={game.id}
                className={`hover:bg-aha-orange cursor-pointer transition ease-in-out duration-200 py-3 relative
            ${formData.game === game ? "bg-aha-orange" : "bg-aha-black"}

            ${
                lockSubscription && game.subscription
                    ? "cursor-not-allowed"
                    : null
            }
            `}
                onClick={() => {
                    if (!(lockSubscription && game.subscription)) {
                        setFormData((currentState: any) => {
                            return {
                                ...currentState,
                                game: currentState.game === game ? null : game,
                            };
                        });
                    } else {
                        setShowSubscriptionModal(true);
                    }
                }}
            >
                {lockSubscription && game.subscription ? (
                    <div className="absolute w-6 h-6 -top-3 -right-2  pointer-events-none z-50">
                        <img src={lockIcon} alt="Lock" />
                    </div>
                ) : null}
                <div
                    className={
                        "text-2xl tracking-wider font-century-gothic-bold"
                    }
                >
                    {game.title}
                </div>
            </div>
        );

        return (
            <div className={"flex-1 text-center "}>
                <div className={"flex flex-col gap-6 h-full"}>
                    <div
                        className={`border-4 border-gray pb-4 xl:flex-grow ${
                            inputLevel === 3 && "bg-gray-100"
                        }`}
                    >
                        <div className="mb-3 mt-6">
                            <div className="mx-auto border w-max border-black rounded-full px-3">
                                Step 4
                            </div>

                            <div className="my-5 text-aha-black">
                                <p className="text-2xl mb-2 font-century-gothic-bold">
                                    Pick Your Game
                                </p>
                            </div>
                        </div>

                        <div
                            className={
                                "space-y-8 uppercase pt-8 px-2 md:px-8 text-white"
                            }
                        >
                            {games.map((game: IGame, index: number) =>
                                renderGameItem(game, index)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={"container pt-4 pb-16"}>
            <Link to={"/"}>
                <img
                    src={backButtonImage}
                    className="h-14 mb-4 cursor-pointer"
                    alt="Back button"
                />
            </Link>
            <div className="mb-4 flex flex-col lg:items-center lg:grid lg:grid-cols-4 gap-4">
                <div className="col-span-2">
                    <p className="text-xl md:text-2xl font-century-gothic-bold md:mb-0">
                        Build your game in 4 easy steps
                    </p>
                </div>
                <div></div>
                <button
                    className={`text-white text-lg tracking-widest py-2 cursor-pointer ${
                        allSelected
                            ? "bg-aha-orange"
                            : "bg-gray-200 pointer-events-none"
                    }`}
                    onClick={() => onSubmit()}
                >
                    Let's Play!
                </button>
            </div>

            <div
                className={
                    "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"
                }
            >
                {renderStep1()}

                {renderStep2()}

                {renderStep3()}

                {renderStep4()}
            </div>

            
            <ModalAfterLogin 
                showModal={showOneTimeModal} 
                onClose={(ev: boolean) => {
                    setShowOneTimeModal(ev);
                }}
            />

            <PositionAndLevelInfoModal
                showModal={isVideoModalOpen}
                onClose={(ev: any) => setActiveInfoModal(ev)}
                activeInfoModal={activeInfoModal}
            />

            <VideoModal
                showModal={isVideoModalOpen}
                onClose={(ev: any) => setIsVideoModalOpen(!isVideoModalOpen)}
            />

            <SoundSelectorModal
                selectedSound={selectedSound}
                childSelectedSound={childSelectedSound}
                showModal={showSoundsModal}
                lockSubscription={lockSubscription}
                onClose={(sound: ISound | undefined) => {
                    setShowSoundsModal(false);
                    setWordPositionsSelected([]);
                    setLevelSelected(undefined);
                    setWordPositionsAllowedBySound(sound?.wordPositionsAllowed || []);
                    setLevelsAllowedBySound(sound?.levelsAllowed || []);
                    setchildSelectedSound(sound);
                    setFormData((currentState: any) => {
                        return {
                            ...currentState,
                            sound: sound ? sound.id : null,
                        };
                    });
                }}
                onLockClick={() => setShowSubscriptionModal(true)}
            />

            <LevelSelectorModal
                showModal={showLevelsModal}
                onClose={(level: any) => {
                    setShowLevelsModal(false);
                    if(level){
                        onSubmit(level);
                    }
                }}
                selectedLevel={readingSelectedLevel}
            />

            <SubscriptionModal
                showModal={showSubscriptionModal}
                onClose={() => setShowSubscriptionModal(false)}
            />
        </div>
    );
};

export default LetsPlay;
