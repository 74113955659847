import { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Blog from "../../containers/blog/Blog";
import NoMatch from "../../containers/noMatch";
import OurStory from "../../containers/static-pages/OurStory";
import PrivacyPolicy from "../../containers/static-pages/PrivacyPolicy";
import TermsAndConditions from "../../containers/static-pages/TermsAndConditions";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import AuthContext from "../context/authContext/AuthContext";
import { ACTIONS } from "../context/authContext/Constants";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ReactGA from "react-ga4";
import SideBar from "../components/SideBar";
import { getFlags } from "../services/flags/flags.service";
import { IUser } from "../interfaces/user.interface";
import FreeTrialReminderModal from "../../containers/subscription/ReminderFreeTrialModal";
import { IFlagsResponse } from "../interfaces/flags.interface";
import lodash from "lodash";
import { getMessages } from "../services/messages/messages.service";
import { IMessage } from "../interfaces/messages.interface";
import MessageModal from "../components/MessageModal";
import { IntercomProvider } from "react-use-intercom";

const WebRoutes = () => {
    const { dispatch, state } = useContext(AuthContext);
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID || '';
    const [open, setOpen] = useState(false);
    const [showFreeTrialModal, setShowFreeTrialModal] = useState(false);
    const [flags, setFlags] = useState<IFlagsResponse>();
    const [messages, setMessages] = useState<IMessage[]>();

    const handleCloseSideBar = () => {
        setOpen(false);
    };

    const handleOpenSideBar = () => {
        setOpen(true);
    };

    const updateStoredUser = (newUser: IUser) => {
        dispatch({
            type: ACTIONS.SET_USER,
            user: { ...newUser },
        });
    };
    
    const handleFlags = async (user: IUser) => {
        const { response } = await getFlags();

        if (response) {
            const equals = lodash.isEqual(user, response.user);

            if (!equals) {
                //update local storage user
                updateStoredUser(response.user);
            }

            setFlags(response);

            if (
                response.show3DaysReminding ||
                response.show7DaysReminding ||
                response.showLastReminding ||
                response.showFreeTrialEndReminding
            ) {
                setShowFreeTrialModal(true);
            }
        }
    };

    const handleMessages = async (user: IUser) => {
        const { response } = await getMessages();

        if (response && response.length) {
            setMessages(response);
        }
    };

    const handleInit = async (user: IUser) => {
        if (user) {
            await handleFlags(user);
            await handleMessages(user);
        }
    };

    const handleCloseRemindersModal = () => {
        setShowFreeTrialModal(false);
    };

    const handleCloseMessageModal = () => {
        setMessages(undefined);
    };

    useEffect(() => {
        if (user) {
            handleInit(JSON.parse(user));
        }
    }, [user]);

    useEffect(() => {
        if (token && user) {
            dispatch({ type: ACTIONS.SET_TOKEN, token: JSON.parse(token) });
            dispatch({ type: ACTIONS.SET_USER, user: JSON.parse(user) });
        }
        dispatch({ type: ACTIONS.SET_LOADING, isLoading: false });
    }, []);

    useEffect(() => {
        if (process.env.REACT_APP_ENV !== "local") {
            if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
                ReactGA.initialize(
                    process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
                );
                ReactGA.send({ hitType: "pageview", page: "/" });
            }
        }
    }, []);

    return (
        <div>
            <IntercomProvider appId={intercomAppId} autoBoot={true} autoBootProps={{ 
                    userId: state?.user?.id || '',
                    name: state.user ? `${state.user.firstname ?? ''} ${state.user.lastname ?? ''}` : '', 
                    email: state?.user?.email,
                    customAttributes: { group_role: state?.user?.group_role }
                }}>
                <Header openSideBar={handleOpenSideBar} />
                <ScrollToTop>
                    <Switch>
                        <Route path="/not-found" component={NoMatch} />

                        <Route path="/app" component={PrivateRoute} />

                        <Route
                            exact
                            path="/terms-and-conditions"
                            component={TermsAndConditions}
                        />

                        <Route
                            exact
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                        />

                        <Route exact path="/our-story" component={OurStory} />

                        <Route path="/blog" component={Blog} />

                        <Route path="/" component={PublicRoute} />
                    </Switch>
                </ScrollToTop>
                <Footer />
                <SideBar open={open} onClose={handleCloseSideBar} />

                {/* FREE TRIAL MODAL */}
                {state.user && flags && (
                    <FreeTrialReminderModal
                        data={flags}
                        user={state.user}
                        showModal={showFreeTrialModal}
                        onClose={handleCloseRemindersModal}
                    />
                )}

                {/* MESSAGES MODAL */}
                {state.user && messages && (
                    <MessageModal
                        showModal={Boolean(messages)}
                        messages={messages}
                        onClose={handleCloseMessageModal}
                    />
                )}
            </IntercomProvider>
        </div>
    );
};

export default WebRoutes;
