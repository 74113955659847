import { gql } from "@apollo/client";

export const listGamesQuery = gql`
    query getGames {
        getGames {
            pagingMeta {
                count
            }
            data {
                id
                title
            }
        }
    }
`;

export const playAGameMutation = gql`
    query PlayAGame($input: PlayAGameInput!) {
        PlayAGame(input: $input) {
            gameLogId
            game {
                id
                title
                subscription
                live
                gameFiles {
                    data
                    framework
                    loader
                    wasm
                }
                timesPlayed
            }
            flashcards {
                id
                wordPosition
                sound {
                    id
                    title
                    subTitle
                    subscription
                    live
                    parentCategory {
                        id
                    }
                }
                carrierPhrase {
                    id
                    phrase
                    audio
                }
                sentence {
                    sentence
                    audio
                }
                image
                wordAudio
                live
            }
            tipsAndTricks {
                id
                image
                header
                makeSound
                helpfulTips
                commonErrors
                references
                live
                subscription
                createdAt
                updatedAt
            }
            readingContent {
                id
                title
                file
            }
            assets {
                name
                url
            }
        }
    }
`;

export const getGameOptionQuery = gql`
    query GetGameOption {
        getGameOption {
            sounds {
                parent {
                    id
                    title
                    subTitle
                    subscription
                    live
                    wordPositionsAllowed
                    levelsAllowed
                }
                children {
                    id
                    title
                    subTitle
                    subscription
                    live
                    wordPositionsAllowed
                    levelsAllowed
                }
            }
            wordPositions
            levels {
                id
                title
                subscription
                live
            }
            readingLevels
            games {
                id
                title
                subscription
                live
                timesPlayed
                reinforcement
            }
        }
    }
`;
