import apolloClient from "../../../config/apolloClient";
import { ReadingLevelEnum } from "../../enums/reading-level.enum";
import {
    getGameOptionQuery,
    listGamesQuery,
    playAGameMutation,
} from "./game.gql";

export interface IPlayAGamePayload {
    game: string;
    sound: string;
    wordPositions: string[];
    level: string;
    amount: number;
    readingLevel?: ReadingLevelEnum;
    deviceType?: string;
}

export const listGamesRequest = async () => {
    const { data, errors } = await apolloClient.query({
        query: listGamesQuery,
    });

    if (errors) {
        return { errors };
    }

    return { response: data.ListGames };
};

export const playAGameRequest = async (payload: IPlayAGamePayload) => {
    const { data, errors } = await apolloClient.query({
        query: playAGameMutation,
        fetchPolicy: 'no-cache',
        variables: {
            input: {
                ...payload,
            },
        },
    });

    if (errors) {
        return { errors };
    }

    return { response: data.PlayAGame };
};

export const getGameOptionsRequest = async () => {
    const { data, errors } = await apolloClient.query({
        query: getGameOptionQuery
    });

    if (errors) {
        return { errors };
    }

    return { response: data.getGameOption };
};

export const getSounds = () => {
    return [
        {
            title: "R",
            subTitle: "",
            value: "R",
        },
        {
            title: "S",
            subTitle: "",
            value: "S",
        },
        {
            title: "L",
            subTitle: "",
            value: "L",
        },
        {
            title: "SH",
            subTitle: "",
            value: "SH",
        },
        {
            title: "CH",
            subTitle: "",
            value: "CH",
        },
        {
            title: "J",
            subTitle: "",
            value: "J",
        },
        {
            title: "TH",
            subTitle: "voiced",
            value: "TH_VOICE",
        },
        {
            title: "TH",
            subTitle: "voiceless",
            value: "TH_VOICELESS",
        },
    ];
};

export const getPositions = () => {
    return [
        {
            title: "INITIAL",
            subTitle: "(the beginning of the word)",
            value: "INITIAL",
        },
        {
            title: "MEDIAL",
            subTitle: "(the middle of the word)",
            value: "MEDIAL",
        },
        {
            title: "FINAL",
            subTitle: "(the end of the word)",
            value: "FINAL",
        },
    ];
};

export const getLevels = () => {
    return [
        {
            title: "WORD",
            value: "WORD",
        },
        {
            title: "PHRASE",
            value: "PHRASE",
        },
        {
            title: "SENTENCE",
            value: "SENTENCE",
        },
    ];
};
