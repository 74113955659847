import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { GraphQLError} from "graphql";
import {
    clearLocalStorage,
    exchangeRefreshTokenRequest,
} from "../shared/services/auth/auth.service";
//LIBS & CONSTANTS

const REACT_APP_API_URL = process.env[`REACT_APP_API_URL`];
const REACT_APP_GRAPH_ENDPOINT = process.env[`REACT_APP_GRAPH_ENDPOINT`];

const httpLink = createHttpLink({
    uri: `${REACT_APP_API_URL}/${REACT_APP_GRAPH_ENDPOINT}`,
});

const authLink = setContext(async (_, { headers }) => {
    const token = JSON.parse((await localStorage.getItem("token")) || "{}");
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token.accessToken}` : "",
        },
    };
});
/**
 * response: response from the server
 * graphQLErrors: GraphQLErrors
 * networkError: network Error
 */
const error = onError(({ graphQLErrors, networkError, response }) => {
    try {
        if (graphQLErrors && graphQLErrors[0] !== null) {
            const tempError: AHAGraphQLError[] = JSON.parse(
                JSON.stringify(graphQLErrors)
            );

            const errorMessage = tempError.map(
                ({ message, details, internalCode }) => {
                    if (message === "Unathorized graphql request") {
                        const token = JSON.parse(
                            localStorage.getItem("token") || "{}"
                        );
                        if (token && token.accessToken) {
                            console.log("🔄 ~ Refreshing Token!!!");

                            exchangeRefreshTokenRequest(token.refreshToken)
                                .then((resp) => {
                                    token.accessToken =
                                        resp.response.accessToken;
                                    localStorage.setItem(
                                        "token",
                                        JSON.stringify(token)
                                    );
                                    window.location.reload();
                                })
                                .catch(() => {
                                    clearLocalStorage();
                                    window.location.reload();
                                });
                        } else {
                            clearLocalStorage();
                            window.location.reload();
                        }
                    }

                    return `${message} `;
                }
            );

            console.error(
                "🚀 ~ file: apolloClient.tsx ~ errorMessage",
                errorMessage
            );
        }

        if (networkError) console.log(`[Network error]: ${networkError}`);
    } catch (error) {}
});

const timeStartLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: performance.now() })
    return forward(operation)
  })
  
  const logTimeLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(data => {
      // data from a previous link
      const time = performance.now() - operation.getContext().start;

      const requestTime = JSON.parse(
        localStorage.getItem("responseTimeObj") || "{}"
        );
        requestTime[operation.operationName] = Number(time.toFixed(2));
        localStorage.setItem(
            "responseTimeObj",
            JSON.stringify(requestTime)
        );
        return data
    })
  })
  
  const responseTimeLogger = timeStartLink.concat(logTimeLink)

const cache = new InMemoryCache({});

const apolloClient = new ApolloClient({
    // link: error.concat(authLink.concat(httpLink)),
    link: from([error, responseTimeLogger, authLink.concat(httpLink)]),
    cache: cache,
    defaultOptions: {
        mutate: { errorPolicy: "all" },
        query: { errorPolicy: "all" },
    },    
});

export default apolloClient;

export interface AHAGraphQLError extends GraphQLError {
    details: string[];
    internalCode: number;
}
