import gql from "graphql-tag";

export const loginMutation = gql`
    mutation Login($input: LoginInput!) {
        Login(input: $input) {
            ...loginFields
        }
    }

    fragment loginFields on LoginResponse {
        accessToken
        refreshToken
        user {
            id
            firstname
            lastname
            email
            role
            firstLoginWeb
            hideLoginPopUpWeb
            clinicianInfo {
                ashaNumber
                schoolDistrict
                schoolName
                city
                state
                privatePracticeName
            }
            parentInfo {
                students {
                    id
                    firstname
                    lastname
                }
            }
            profileStudentUsed {
                id
                firstname
                lastname
            }
            subscriptionTier
            freeTrialEndsAt
            createdAt
            updatedAt
        }
    }
`;

export const loginFromAdminMutation = gql`
    mutation LoginFromWeb($input: LoginFromWebInput!) {
        LoginFromWeb(input: $input) {
            accessToken
            refreshToken
            user {
                id
                firstname
                lastname
                email
                role
                firstLoginWeb
                hideLoginPopUpWeb
                profileStudentUsed {
                    id
                    firstname
                    lastname
                }
                clinicianInfo {
                    ashaNumber
                    schoolDistrict
                    schoolName
                    city
                    state
                    privatePracticeName
                }
                subscriptionTier
                freeTrialEndsAt
                createdAt
                updatedAt
            }
        }
    }
`;

export const registerMutation = gql`
    mutation Registration($input: RegistrationInput!) {
        Registration(input: $input) {
            ...messageFields
        }
    }

    fragment messageFields on MessageResponse {
        message
    }
`;

export const loggedInUserQuery = gql`
    query LoggedInUser {
        LoggedInUser {
            id
            email
            active
            firstname
            lastname
        }
    }
`;

export const loggedUserWithStudentQuery = gql`
    query findUserWithStudentsRelated {
        findUserWithStudentsRelated {
            id
            firstname
            lastname
            parentInfo {
                students {
                    id
                    firstname
                    lastname
                }
            }
            profileStudentUsed {
                id
                firstname
                lastname
            }
        }
    }
`;

export const exchangeRefreshTokenMutation = gql`
    query ExchangeRefreshToken($input: ExchangeRefreshTokenInput!) {
        ExchangeRefreshToken(input: $input) {
            accessToken
            refreshToken
        }
    }
`;

export const requestNewPasswordMutation = gql`
    mutation RequestNewPassword($input: RequestNewPasswordInput!) {
        RequestNewPassword(input: $input) {
            message
        }
    }
`;

export const requestChangePasswordMutation = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
        ChangePassword(input: $input) {
            email
        }
    }
`;
